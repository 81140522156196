import Accordion from "react-bootstrap/Accordion";
import { MinusIcon, PlusIcon } from "../common/icon";

const RxFaqList = () => {
  const generalFaq = [
    {
      id: 1,
      ques: " איך זה עובד?",
      ans: "לאחר שנוודא שיש לך רישיון רפואי בתוקף וביטוח אחריות מקצועית, נפתח עבורך חשבון אצלנו במערכת ונייצר עבורך חתימה דיגיטלית מאושרת אישית. לאחר מכן תוכל/י לכתוב סיכומי טיפול/ מרשמים שישלחו ישירות לבתי המרקחת. המטופל יקבל הודעת וואטסאפ אוטומטית מאיתנו ברגע שהסיכום/מרשם יחתם כך שתהיה לו/ה כתובת לשאלות וסיוע טכני במידת הצורך.",
    },
    {
      id: 2,
      ques: " האם ניתן לייצא את המידע של מטופליי?",
      ans: "כן, ניתן להוריד את כל המידע שנשמר במערכת היידוק בלחיצת כפתור. המידע יתקבל בפורמט PDF ואקסל.",
    },
    {
      id: 3,
      ques: " האם יש התחייבות למנוי?",
      ans: "אין התחייבות וניתן לסיים את המנוי בכל עת בכפוף להודעה מראש בהתראה של 7 ימים לפני תום החודש הקלנדרי.",
    },
    {
      id: 4,
      ques: " האם יש הגבלה למספר המרשמים שאוכל לרשום?",
      ans: "אין הגבלה למספר המרשמים, דמי המנוי מכסים כתיבת מרשמים ללא הגבלה.",
    },
    {
      id: 5,
      ques: " אם המטופל שלי לא מסתדר עם משיכת המרשם מבית המרקחת, מה קורה?",
      ans: "אין שום בעיה, צוות שירות הלקוחות של היידוק ידאג לטיפול עד לשביעות רצון מלאה של המטופל, ושלך כמובן. אנחנו זמינים בWhatsApp ממנו המטופלים מקבלים הודעות אוטומטיות בין השעות 08:00 ל-22:00.",
    },
    {
      id: 6,
      ques: " האם כל מגוון התרופות של בתי המרקחת בארץ זמינים לי לרשום אותם?",
      ans: "כן, המערכת מתבססת על קטלוג תרופות מקיף, אותו קטלוג של קופות החולים ולכן תוכלו למצוא שם את כל מגוון התרופות.",
    },
    {
      id: 7,
      ques: " כיצד מתבצע איסוף המרשם מסופר פארם?",
      ans: "לאיסוף המרשם ניתן לפנות לכל סניף של סופר פארם ולהציג את תעודת הזהות של המטופל. שימ.י לב, ניתן להראות לרוקח את ההודעה הבאה: המרשם הינו מרשם פרטי ולכן נמצא במערכת המרשמים הדיגיטלית של סופרפארם, ולא במערכת ירפא. במידה ומשהו לא מסתדר, ניתן להנחות את המטופל/ת לפנות אלינו בWhatsApp ממנו קיבל/ה הודעות מאיתנו. שעות פעילות התמיכה הטכנית הן בין 08:00 ל-22:00.",
    },
    {
      id: 8,
      ques: " האם הלקוח יכול לרכוש את התרופות דרך קופת החולים?",
      ans: "כרגע המרשמים הם מרשמים פרטיים כך שלא ניתן לקבל אותם באופן מסובסד. ניתן להנחות את המטופלים לשלוח את המרשם הדיגיטלי<br>לרופא המשפחה במייל או דרך האפליקציה של הקופה ולנסות להמיר למרשם מסובסד.",
    },
    {
      id: 9,
      ques: " האם המידע נשמר בצורה מאובטחת?",
      ans: "אנו עומדים כבר שלוש שנים בתקני ISO של אבטחת מידע (ISO 27701 & ISO 27799) בהסמכת מכון התקנים ומשקיעים את מירב המשאבים והמאמצים לשמור על פרטיות הלקוחות ואבטחת המידע שלהם.",
    },
  ];

  return (
    <div className="new-faq-list-section">
      <div className="new-faq-list">
        <Accordion defaultActiveKey="0">
          {generalFaq.map((e, i) => (
            <Accordion.Item
              eventKey={`${i}`}
              key={i}
              className="first-accordian"
            >
              <Accordion.Header>
                {" "}
                <div className="rx-icon close-icon">
                  <MinusIcon />
                </div>
                <div className="rx-icon open-icon">
                  <PlusIcon />
                </div>{" "}
                {e?.ques}
              </Accordion.Header>
              <Accordion.Body>
                <p dangerouslySetInnerHTML={{ __html: e?.ans }} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default RxFaqList;
